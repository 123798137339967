/*VARS*/
:root {
  /* colors */
  --background: #FFF;
  --backgroundSecondary: #F7F7F7;
  --textPrimary: #212121;
  --textSecondary: #626262;

  --error: #8E0000;

  --brand-primary: #E44867;
  --brand-secondary: #BE00B6;
  --brand-tertiary: #F97238;
  --highlight: #F9C338;

  --gray200: #FBFBFB;
  --gray300: #F7F7F7;
  --gray400: #B4B4B4;
  --gray500: #7A7A7A;
  --gray600: #3D3D3D;
  --gray800: #212121;
  --gray900: #141414;

  --green500: #80BF28;

  --primary100: #FDE2EC;
  --primary200: #FDC3D1;
  --primary600: #E44867;

  --secondary100: #F9ECF7;
  --secondary200: #F9E2FD;
  --secondary600: #BE00B6;

  --white: #FFF;

  /* borders and utils */
  --borderColor: #E7E7E7;
  --standardBorder: 1px solid #E7E7E7;
  --secondaryBorder: 1px solid #212121;
  --standardRadius: 8px;
  --smallRadius: 4px;
  --focusGradientPrimary:  90deg, #F0631B 0%, #BE01B6 100%;
  --focusGradientSecondary: 66deg, var(--white) 0%, var(--white) 33%, var(--primary100) 67%, rgb(239 188 234) 100%;
  --primaryGradient: 270.24deg, #E44867 3.69%, #BE00B6 99.51%;
  --secondaryGradient: 343.69deg, #E44867 12.54%, #BE00B6 92.92%;


  /* Sizing */
  --sideNav-width: 276px;
  --topNav-height: 50px;

  /* Screen Size Breakpoints */
  --smallScreen: 480px;
  --mediumScreen: 768px;
  --largeScreen: 992px;
  --xLargeScreen: 1200px;
}

body {
    margin: 0;
    font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background);
    color: var(--textPrimary);
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--textPrimary);
  text-decoration: none;
}

a:focus-visible {
  outline: 1px solid var(--brand-secondary);
}

button:focus-visible {
  outline: 1px solid var(--brand-secondary);
}


#content {
  display: flex;
  overflow-y: hidden;
}

#detail {
  flex: 1;
  padding: 24px;
  max-width: calc(100vw - var(--sideNav-width));
  /* minus nav & padding */
  max-height: calc(100vh - var(--topNav-height));
  overflow-y: auto;
  box-sizing: border-box;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}

.flexCenterCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flexColumnStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Legacy ***************************************************************************  */


.textarea-container {
    position: relative;
    overflow: auto;
    flex: 1;
}

.recommended-prompts {
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 26px;
}

.recommended-prompts > h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.15px;
    color: var(--textSecondary);
    margin: 0;
    margin-bottom: 5px;
}

.recommended-prompts > h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #E44867;
    margin: 0;
    margin-top: 5px;
    cursor: pointer;
}

.recommended-prompts > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    letter-spacing: 0.3px;
    color: var(--textSecondary);
}


.recommended-prompts-container > div {
    background: #141414;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    padding: 3px 6px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 9px;
    color: var(--textSecondary);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.3px;
    height: 36px;
    padding: 0;
    padding-left: 5px;
    margin: 0;

    display: flex;
    align-items: center
}

.recommended-prompts-container > div.active {
    color: white;
    font-weight: 700;
}

.recommended-prompts-container > div:not(:first-child) {
    border-top: var(--standardBorder);
}

.recommended-prompts-container > div:hover {
    background: #434343
}

.textarea-container > .textarea {
    background: var(--backgroundSecondary);
    border: var(--standardBorder);
    border-radius: var(--standardRadius);
    width: calc(100% - 32px);
    padding: 15px;
    outline: none;
    max-height: 70px;
    height: 70px;
    resize: none;
    font-weight: 500;
    line-height: 20px;
    color: var(--textSecondary);
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.15px;
}

.textarea-container > .textarea-placeholder {
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.15px;
    color: var(--textSecondary);
    opacity: 0.5;
    position: absolute;
    top: 15px;
    left: 15px;
    width: calc(100% - 40px);
    font-weight: 400;
}

.textarea-container > textarea:focus + .textarea-placeholder {
    display: none;
}

#ai-output {
    margin-top: 15px;
    margin-bottom: 10px;
    flex: 5;
}

#ai-output-list {
    height: 100%;
}

.ai-output-collapsed {
    margin-left: 25px;
    margin-bottom: 10px;
    border: 1px solid #E44867;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 5px 14px;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.15px;
    color: #C6C6C6;
    cursor: pointer;
}

.ai-output-collapsed > div {
    margin-right: 10px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gradient-border-container {
    position: relative;
}

.gradient-content {
    position: relative;
    left: 1px;
    top: 1px;
    background: #141414;
    width: calc(100% - 32px);
    border-radius: 8px;

    padding: 15px;

    height: calc(100% - 30px);
}

.gradient-border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -2px;
    background: linear-gradient(-45deg, #BF00B8, #FD792F);
    border-radius: 8px;
    z-index: 0;
}

#ai-output h5 {
    background: #2C2C2C;
    border-radius: 4px;
    padding: 2px 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.15px;
    margin-top: 0;
    margin-bottom: 0;

    color: #C6C6C6;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

#ai-output-list {
    height: calc(100vh - 161px - 40px);
    overflow-y: scroll;

    position: relative;
    top: -15px;
}

#ai-output p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.15px;
    /*  opacity: 0.5;*/

    /*  min-height: 250px;*/

    /*  max-height: calc(100vh - 295px);*/


    overflow-y: auto;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 18px;
}

#ai-output.loaded p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: 0.15px;
}

#ai-output.error p {
    opacity: 0.8;
}

#ai-output.error .gradient-border {
    background: #FF3A3A;
}

#output-header {

    display: flex;
    align-items: center;

    justify-content: space-between;
    margin: 0;
}

#output-header > h4 {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
}

#upload-transcript-container {
    padding-top: 26px;
    max-width: 960px;
    min-width: 515px;

    width: calc(100% - 150px);

    margin: auto;
}

#upload-transcript-container > .pink {
    background: var(--secondary100);
    border-radius: 8px;
    display: flex;
    width: calc(100% - 30px);
    justify-content: space-between;
    padding: 0 12px;
    align-items: center;
}

#upload-transcript-container > .pink > p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--textSecondary);
}

#upload-transcript-container > .pink > div {
    display: flex;
}

#upload-transcript-container > .pink > div > div:last-child {
    margin-left: 15px;
}

#upload-transcript-container > .pink > div > div {
    border: 2px solid var(--brand-primary);
    border-radius: 3px;
    padding: 4px 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
    cursor: pointer;
}

#upload-transcript-container > .pink > div > div:focus-visible {
  outline: 1px solid var(--secondary600);
  outline-offset: 2px;
  text-decoration: underline;
}
#upload-transcript-container > h5 {
    font-style: normal;
    font-weight: 900;
    line-height: 25px;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 20px;
}

#upload-transcript-container > input[type=text], #settings-subscriptions input[type=text], #user-not-logged-in-state input[type=text], #settings-integrations input[type=text] {
    background: var(--backgroundSecondary);
    border: var(--standardBorder);
    border-radius: var(--standardRadius);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--textSecondary);
    outline: none;
    padding: 6px 12px;
    width: calc(100% - 24px);
    margin-top: 20px;
}

#upload-transcript-container .selected-file {
    margin-top: 9px;
}

#upload-transcript-container .selected-file > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border: var(--standardBorder);
    border-radius: 10px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    overflow: hidden;

}

#upload-transcript-container .selected-file > div > div:first-child {
    padding: 12px 24px;
    gap: 10px;
    background: var(--backgroundSecondary);
}

#upload-transcript-container .selected-file > div > div:first-child > svg {
    position: relative;
    top: 3px;
}

#upload-transcript-container .selected-file > div > .file-name {
    flex: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: var(--textSecondary);
    padding-left: 15px;
}

#upload-transcript-container .selected-file > div > div:last-child {
    cursor: pointer;
    margin-right: 20px;
    position: relative;
    top: 2px;
}

.react-datepicker-wrapper input[type=text] {
    background: var(--backgroundSecondary);
    border: var(--standardBorder);
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    outline: none;
    width: calc(100% - 45px);
    padding-left: 32px;
}

.react-datepicker-wrapper svg {
    fill: var(--brand-primary);
    padding-left: 12px !important;
    padding-top: 10px !important;
    width: 16px;
    height: 12px;
}

.edge-case-state {
    width: 100%;
    height: calc(100vh - var(--topNav-height));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.edge-case-state h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
}

.edge-case-state span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;
    letter-spacing: 0.15px;
}

.error {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: 0.15px;

    color: #FF3A3A;
}

.go-to-extension {
    position: absolute;
    right: 0;
    top: 0;
    width: 155px;
    padding: 40px;
}

.go-to-extension h3, .go-to-extension p {
    text-align: left;
}

.go-to-extension h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 14.1299px;
    line-height: 18px;

}

.go-to-extension span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.3px;
}

.typewriter-container {
    position: relative;
    width: 100%;
    height: 40px;
}

.typewriter {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.typewriter > div {
    display: inline-block;

    overflow: hidden; /* Ensures the content is not revealed until the animation */

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;
    letter-spacing: 0.15px;

    /* primary/grey */

    color: #C6C6C6;

    border-right-width: 3px;

    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */

    width: 0;

    margin: 0;
}

.typewriter.line-1 > div {
    animation: typing 2.5s steps(40, end),
    blink-caret .75s step-end infinite,
    disappearing 0s linear 8s;

    animation-fill-mode: forwards;
}

.typewriter.line-2 > div {
    animation: typing 2.5s steps(40, end) 8s,
    blink-caret .75s step-end infinite,
    disappearing 0s linear 16s;

    animation-fill-mode: forwards;
}

.typewriter.line-3 > div {
    animation: typing 2.5s steps(40, end) 16s,
    blink-caret .75s step-end infinite,
    disappearing 0s linear 24s;

    animation-fill-mode: forwards;
}

.typewriter.line-4 > div {
    animation: typing 2.5s steps(40, end) 24s,
    blink-caret .75s step-end infinite,
    disappearing 0s linear 32s;
    animation-fill-mode: forwards;
}

.typewriter.line-5 > div {
    animation: typing 2.5s steps(40, end) 32s,
    blink-caret .75s step-end infinite;
    animation-fill-mode: forwards;
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }
    to {
        width: 100%
    }
}

@keyframes disappearing {
    from {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to {
        border-color: transparent
    }
    50% {
        border-color: orange;
    }
}

@keyframes progress-bar {
    from {
        width: 0
    }
    to {
        width: calc(100% - 4px)
    }
}

.output-loader {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    top: 50px;
}

.output-loader > h3 {
    margin-top: 10px;
    margin-bottom: 15px;
}

.output-loader > .typewriter {
    margin-bottom: 10px;
}

.output-loader > .loader-container {
    width: 185px;
    height: 8px;
    background: var(--backgroundSecondary);
    border-radius: 6px;
    margin-top: 12px;
}

.output-loader > .loader-container > .loader-inner {
    width: 69px;
    height: 4px;
    background: #F97238;
    border-radius: 2px;
    margin: 2px;
    animation: progress-bar 40s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

.generate-ai-summary .loader-container {
    width: 185px;
    height: 8px;
    background: var(--backgroundSecondary);
    border-radius: 6px;
    border: var(--standardBorder);
    margin: auto;
    margin-top: 25px;

}

.generate-ai-summary .loader-container > .loader-inner {
    width: 69px;
    height: 4px;
    background: #F97238;
    border-radius: 2px;
    margin: 2px;
    animation: progress-bar 30s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    position: relative;
}

#prompts-list {
    overflow-y: auto
}

#prompts-list > button:hover {
    background: #434343 !important;
}

#prompt-template-dropdown, #sort-by-dropdown {
    background: var(--background);
    border: var(--standardBorder);
    border-radius: var(--standardRadius);
    min-width: 100px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    letter-spacing: 0.15px;
    position: relative;
    left: 1px;

    z-index: 999;
}

#prompt-template-dropdown.open, #sort-by-dropdown.open {
    border-radius: 5px 5px 0 0;
}

#prompt-template-dropdown .label, #sort-by-dropdown .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 12px;
    border-radius: 4px;

    cursor: pointer;
}

#sort-by-dropdown .label {
    padding: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

#prompt-template-dropdown .label > span, #sort-by-dropdown .label > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#sort-by-dropdown .label > span > svg {
    margin-right: 5px;
    margin-left: 3px;
}

#sort-by-dropdown .label > span {
    display: flex;
}

#sort-by-dropdown .dropdown-content .dropdown-item {
    background: var(--background);

    padding: 6px 27px;

    font-size: 14px;
    /* color: #c6c6c6; */
}

#sort-by-dropdown .dropdown-content .dropdown-item:hover {
    background: var(--primary100);
    color: var(--gray800);
}

#prompt-template-dropdown .dropdown-content, #sort-by-dropdown .dropdown-content {
    position: absolute;
    top: 100%;
    left: -1px;
    width: calc(100% + 2px);
    background: var(--background);
    border-radius: 0 0 5px 5px;
    overflow-y: scroll;
    max-height: calc(100vh - 300px);
}

#prompt-template-dropdown.open, #sort-by-dropdown.open {
    border: var(--standardBorder);

    background: var(--background);
}

#prompt-template-dropdown.open > .label, #sort-by-dropdown.open > .label {
    border-radius: 5px 5px 0 0;
}

#prompt-template-dropdown > .label:hover, #sort-by-dropdown > .label:hover {
    background: var(--primary100);
}

#prompt-template-dropdown.open > .label:hover, #sort-by-dropdown.open > label:hover {
    background: var(--primary100);
}

.dropdown-content > .dropdown-item {
    border: var(--standardBorder);

    padding: 9px 12px;

    cursor: pointer;

}

.dropdown-content > .dropdown-item:hover {
    background: var(--primary100);
}

.dropdown-content > .dropdown-item.inactive {
    pointer-events: none
}

.dropdown-content > .dropdown-item > svg {
    margin-right: 5px;
    position: relative;
    top: 1px;
}

.dropdown-content > .dropdown-item:last-child {
    border-radius: 0 0 5px 5px;
}

.dropdown-content > .dropdown-header {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    background: var(--background);
    text-transform: uppercase;

    padding-left: 12px;
    padding-top: 9px;
    padding-bottom: 5px;
}
.dropdown-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: -99;
}

.checkbox-container {
    /*  position: absolute;*/
    /* background: #212121; */
    border-radius: 4px;
    padding: 10px;
    padding-bottom: 5px;
}

.checkbox, .radio {
    cursor: pointer;

    display: inline-block;
}

.checkbox > .label, .radio > .label {
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: var(--textPrimary);
    margin-left: 7px;
    position: relative;
    font-size: 12px;
    bottom: 4px;
}

.copy-btn-container {
    width: 16px;
    display: inline-block;
    margin-right: 8px;
    position: relative;
    top: 3px;
}

.upload-transcript-tabs {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.upload-transcript-tabs > div {
    background: var(--backgroundSecondary);
    border-radius: 8px 8px 0px 0px;
    border: var(--standardBorder);
    border-bottom: none;
    width: 50%;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    padding: 14px 0;
}

.upload-transcript-tabs > div:focus-visible {
  outline: 1px solid var(--secondary600);
  outline-offset: 2px;
}

.upload-transcript-tabs > div:not(.active) {
    background: var(--backgroundSecondary);
    border-bottom: var(--standardBorder);
    cursor: pointer;
}

.upload-transcript-tab-content {
    background: var(--backgroundSecondary);
    border-radius: 0px 0px 8px 8px;
    border: var(--standardBorder);
    border-top: none;
    height: 330px;

    padding: 10px;
}

.upload-transcript-tab-content > div {
    width: 100%;
    height: 100%;
}

.upload-transcript-dropbox {
    border: 1px dashed #6D6D6D;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
}

.upload-transcript-dropbox.file-selected {
    height: calc(100% - 110px);
}

.upload-transcript-dropbox h5 {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: var(--textSecondary);
    margin: 0;
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.upload-transcript-dropbox p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
     color: var(--textSecondary);
}

.upload-transcript-dropbox a {
    color: #E44867;
    text-decoration: underline;
    cursor: pointer;
}

.upload-transcript-tab-content textarea, .transcript-info-popup-container textarea {
    background: var(--backgroundSecondary);
    border: var(--standardBorder);
    border-radius: var(--standardRadius);
    resize: none;
    padding: 16px;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    outline: none;
}

.transcript-info-popup-container textarea {
    background: var(--backgroundSecondary);
}

.generate-ai-summary > div > h5 {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    color: #C6C6C6;

    margin-top: 10px;
    margin-bottom: 10px;
}

.transcript-info-popup-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;
}

.transcript-info-popup-container > .popup-background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .7);
}

.transcript-info-popup-container > .popup-content {
    width: 100%;
    max-width: 560px;
    overflow-y: auto;
    background: var(--background);
    border: var(--standardBorder);
    border-radius: 8px;
    z-index: 99999999;
    position: relative;

    padding: 15px 20px;

    max-height: 90%;
}

.transcript-info-popup-container > .popup-content > .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.transcript-info-popup-container h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;

    margin-bottom: 10px;
}

.transcript-info-popup-container h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.transcript-info-popup-container p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.15px;
}

.transcript-info-popup-container ul {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.15px;
    padding-left: 20px;

}

.transcript-info-popup-container li.margin-left {
    margin-left: 20px;
}

.transcript-info-popup-container.out-of-credits {
    text-align: center;
}

.transcript-info-popup-container.out-of-credits h1 {
    text-align: center;

    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 110% */
    letter-spacing: 0.15px;
}

.transcript-info-popup-container.out-of-credits p {
    text-align: center;

    /* Body L/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.15px;
}

.transcript-info-popup-container.out-of-credits > .popup-content {
    padding: 45px;
}

.transcript-info-popup-container.out-of-credits > .popup-background {
    background: rgba(0, 0, 0, .5);
}

@keyframes ping {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }
    100% {
        transform: scale(3);
        opacity: 0;
    }
}

.ping {
    position: relative;
    width: 10px;
    height: 10px;

    color: #00A3FF;

    opacity: 0.9;

    position: absolute;
    right: -4px;
    top: -4px;
}

.ping:hover {
    opacity: 1;
}

.ping::before,
.ping::after {
    content: '';
    position: absolute;
    /*  top: 0.25em;*/
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: currentColor;
}

.ping::before {
    animation: ping 1.7s ease infinite;
    opacity: 1;
}


.search-badge-container {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;

    margin-top: 5px;

    opacity: 0.9;
}

.search-badge-container:hover {
    opacity: 1;
}

.search-badge-name {
    display: inline-block;

    background: #F97238;
    border-radius: 2px;

    padding: 3px 5px;

    margin-right: 5px;
}

.search-badge-count {
    display: inline-block;

    border: 1px solid #F97238;
    border-radius: 2px;

    padding: 3px 5px;
}

.search-badge-container + p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* or 127% */

    letter-spacing: 0.3px;

    /* primary/grey */

    color: var(--textSecondary);

    margin-top: 5px;
    margin-bottom: 5px;
}


@media screen and (max-width: 1200px) {
    .settings-tabs > div {
        width: 180px !important;
    }
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #E44867;
}

input:focus + .slider {
    box-shadow: 0 0 1px #E44867;
}

input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.email-notifications-switch {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    margin: 10px 0;
    margin-right: 10px
}

.email-notifications-switch > span {
    max-width: calc(100% - 40px);
}

.connected-to-email-badge {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.15px;
    color: var(--white);
    padding: 3px 6px;
    background: var(--brand-secondary);
    border-radius: var(--smallRadius);
}



.row {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
}

#settings-subscriptions .badge {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    padding: 0px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F97238;
    height: fit-content;
}

#team-user-table * {
    /* Subtitle/Bold/12px */
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.15px;
}

#team-user-table .row {
    margin-top: 10px;
    margin-bottom: 10px;
}

#team-user-table > .row > *:first-child {
    width: 40%;

    text-overflow: ellipsis;
    overflow-x: hidden;
}

#team-user-table .row.header > * {
    /* Subtitle/Semi/14px */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.15px;
    margin-bottom: 0;
    margin-top: 0;
}

#team-user-table .row > *:not(:first-child) {
    width: 30%;
}

#team-user-table .row > *:last-child {
    text-align: right;
}

#team-user-table .remove-btn {
    color: #E44867;
    text-align: right;

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.15px;
    text-decoration-line: underline;
    cursor: pointer;
}

.ai-credits-help-container {
    display: inline-block;
    position: relative;
}

.ai-credits-help-container > .ai-credits-popup {
    display: none;
    background: #2C2C2C;
    padding: 5px;
    position: relative;
    left: -95px;
    top: 28px;
    border-radius: 3px;
}

.ai-credits-help-container > svg {
    padding-bottom: 10px;
    padding-top: 10px;
    display: inline-block;
}

.ai-credits-help-container:hover > .ai-credits-popup {
    display: block;
    position: absolute;
}

.swal2-container.swal2-center.swal2-backdrop-show {
    z-index: 9999999999;
}

#stripe-subscription-state {
    margin-top: 10px;
    margin-bottom: 20px;
}

#stripe-subscription-state > span:first-child {
    color: var(--textSecondary);
    /* Subtitle/Bold/12px */
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.15px;
}

#stripe-subscription-state > span:last-child {
    color: var(--textPrimary);
    text-align: right;

    /* Subtitle/Semi/16px */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.15px;
}

#payment-tip-popup {
    text-align: center
}

#payment-tip-popup .popup-content {
    min-width: 700px;
}

#payment-tip-popup h1 {
    color: var(--textPrimary);
    text-align: center;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* H3/Black/24px */

    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

#payment-tip-popup p:not(.gray-text) {
    color: var(--textSecondary);
    text-align: center;

    /* Subtitle/Semi/16px */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.15px;
}

#payment-tip-popup .plan h2 {
    color: var(--textPrimary);
    text-align: center;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 110% */
    letter-spacing: 0.15px;
}

#payment-tip-popup a {
    text-decoration: underline;
    color: #E44867;
    cursor: pointer;
    font-weight: 600;
}

#payment-tip-popup .plan {
    width: 190px;
    padding: 10px;
    border-radius: 8px;
    background: var(--backgroundSecondary);

    border: 3px solid transparent;

    cursor: pointer;
}

#payment-tip-popup .plan.selected {
    border: 3px solid var(--brand-primary);
}

@keyframes fadeinout {
    0% {
        opacity: 0;
        bottom: -1000px;
    }
    1% {
        opacity: 0;
        bottom: -100px;
    }
    20% {
        opacity: 1;
        bottom: 10px;
    }
    80% {
        opacity: 1;
        bottom: 10px;
    }
    99% {
        opacity: 0;
        bottom: -100px;
    }
    100% {
        opacity: 0;
        bottom: -1000px;
    }
}

#briefly-notification {
    border-radius: var(--standardRadius);
    border: var(--standardBorder);
    background: var(--backgroundSecondary);
    position: absolute;
    bottom: 6px;
    right: 5px;
    z-index: 999;
    animation: fadeinout 8s;
    animation-iteration-count: 1;
    bottom: -1000px;
    display: none;
    right: 10px;
}

#briefly-notification.error .icon-not-error {
    display: none;
}

#briefly-notification:not(.error) .icon-error {
    display: none;
}

#briefly-notification > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px;
}

#briefly-notification > div:first-child > span {
    flex: 1;
    margin-left: 5px;
}

#briefly-notification p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: var(--textSecondary);
    margin-top: 5px;
    margin-left: 9px;
}

.LOGGED_IN_AT_LEAST_ONCE_KEY .referral-info-box {
    display: none !important;
}

#feedback-icon-container {
    width: 12px;
    height: 20px;
    display: flex;
    align-items: center;
}

#feedback-icon-container > svg {
    min-width: 12px;
}

#feedback-icon-container > *:not(svg) {
    opacity: 1;
    transition: all 0.3s;
    display: inline-block;
    width: fit-content;
    white-space: nowrap;
}

#feedback-icon-container:hover {
    width: fit-content;
}

#feedback-icon-container:not(:hover) > *:not(svg) {
    opacity: 0;
    width: 0px;
}

#new-briefly-for-slack {
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    margin: 0;
    padding: 3px 8px;
    height: unset;
    margin-right: 8px;
    position: relative;
    bottom: 3px;

    opacity: 1;
}

#new-briefly-for-slack span {
    color: #000;

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#slack-tip-popup h5 {
    color: var(--textPrimary);


    /* Subtitle/Semi/16px */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.15px;
    margin-bottom: 5px;
}

#slack-tip-popup p {
    margin-top: 0;
    color: var(--textSecondary);

    /* Body L/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.15px;
}

#slack-tip-popup .gray-area {
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 6px;
    border: var(--standardBorder);

}

#slack-tip-popup .gray-area * {
    color: var(--textPrimary);
}

#slack-tip-popup .gray-area li {
    color: var(--textSecondary);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.15px;
    margin-bottom: 1em;
}

#slack-tip-popup button {
    margin: 0px auto;
    display: inherit;
    margin-top: 12px;
}


#tooltip-hubspot ul.how-it-works-steps > li {
    margin-bottom: 0.5rem;
}

.hubspot-option > div:last-child > span:first-child {
    color: var(--textPrimary);

    /* Button/Large/16px */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

.hubspot-option > div:last-child > span:last-child {
    color: var(--textSecondary);

    /* Body L/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.15px;
}

.hubspot-loader > b {
    color: var(--textPrimary);
    text-align: center;

    /* H6/Black/16px */
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    margin-top: 20px;
}

.hubspot-loader > p {
    color: var(--textSecondary);
    text-align: center;

    /* Body L/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.15px;
}

#new-briefly-integrations {
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    margin: 0;
    padding: 3px 8px;
    height: unset;
    margin-right: 8px;
    position: relative;
    bottom: 3px;

    opacity: 0.9;

    border-radius: 4px;
    border: var(--standardBorder);
    background: var(--brand-primary);
}

#new-briefly-integrations:hover {
    opacity: 1;
}

#new-briefly-integrations span {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#new-briefly-integrations > svg {
    top: 0;
    left: 0;
}

.integrations-manage-zoom p {
    color: var(--textSecondary);

    font-size: 13px;
    font-style: normal;
    /*  font-weight: 700;*/
    line-height: 16px; /* 142.857% */
    letter-spacing: 0.15px;
}

.integrations-manage-zoom h3 {
    /* Subtitle/Semi/16px */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.15px;
}

.integrations-manage-zoom a {
    color: var(--brand-primary);

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.15px;
    text-decoration-line: underline;
}

.my-prompt-naming-new {
    display: flex;

    align-items: center;

    margin-top: -4px;
    margin-bottom: 2px;

    overflow-x: auto;
}

.my-prompt-naming-new .svg {
    cursor: pointer;
    opacity: 0.9;
}

.my-prompt-naming-new .svg:hover {
    opacity: 1;
}

.my-prompt-naming-new button {
    display: inline-block;
    width: unset !important;
    padding: 0 16px;
    height: 28px !important;

    font-size: 12px;
    line-height: 16px;

    margin-top: 0 !important;
}

.my-prompt-naming-new input {
    border-radius: 5px;
    border: 1px solid var(--background-lighter, #6D6D6D);
    background: var(--background);

    outline: none !important;

    flex: 1;

    color: white;

    height: 25px;

    margin-left: 10px;
    margin-right: 10px;

    padding-left: 6px;

    opacity: 0.9;

    min-width: 50px;
}

.my-prompt-naming-new input:focus, .my-prompt-naming-new input:active, .my-prompt-naming-new input:hover {
    opacity: 1;
}

#settings-integrations.hubspot-salesforce h6 {
    color: var(--textPrimary);

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.15px;

    margin-top: 12px;
    margin-bottom: 0;
}

#settings-integrations.hubspot-salesforce h6 + p {
    color: var(--textSecondary);

    /* Body L/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.15px;

    margin-top: 10px;
    margin-bottom: 20px;
}

.pink-underlined-link {
    color: var(--brand-primary);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-decoration-line: underline;
    cursor: pointer;
}

[meetingid] .recording-in-progress {
    margin-right: -30px;
}

[meetingid]:hover .recording-in-progress {
    margin-right: 0;
}

#in-progress-zoom-meeting-dashboard {
    container-type: inline-size;
    container-name: in-progress-zoom-meeting-dashboard;

    max-height: calc(100vh - var(--topNav-height));
    overflow-y: auto
}

@container in-progress-zoom-meeting-dashboard (max-width: 750px) {
    #in-progress-zoom-meeting-dashboard > div:last-child {
        flex-direction: column;
    }
}

/**::-webkit-scrollbar {
  width: 0px;
}*/

.hubspot-contact-info, .salesforce-contact-info {
    border-radius: 4px;
    background: var(--background);
    padding: 8px;
    margin-left: 0;

    margin-top: -7px;

    margin-bottom: 10px;
}

.hubspot-contact-info:not(.open) > .body, .salesforce-contact-info:not(.open) > .body {
    display: none;
}

.hubspot-contact-info:not(.open) > .header > svg, .salesforce-contact-info:not(.open) > .header > svg {
    transform: rotate(180deg)
}

.hubspot-contact-info > .header, .salesforce-contact-info > .header {
    cursor: pointer;
    color: var(--textPrimary);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.hubspot-contact-info > .header > div, .salesforce-contact-info > .header > div {
    display: flex;
    align-items: center;
}

.hubspot-contact-info > .header > div > svg, .salesforce-contact-info > .header > div > svg {
    margin-right: 5px;
}

.hubspot-contact-info .body, .salesforce-contact-info .body {
    margin-bottom: 0;
    margin-top: 7px;

    color: var(--primary-grey, #C6C6C6);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.save-this-summary-to-hubspot-container.not-checked + .hubspot-contact-info, .save-this-summary-to-salesforce-container.not-checked + .salesforce-contact-info {
    display: none !important;
}

#body:not([transcript-started=true]) #hubspot-contact-tip {
    display: none !important;
}

#hubspot-contact-tip {
    border-radius: 4px;
    background: var(--background);
    margin-top: 0px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#hubspot-contact-tip > div {
    display: flex;
    align-items: center;
}

#hubspot-contact-tip svg {
    margin-right: 4px;
}

#body:not([hubspot-connected=true],[salesforce-connected=true]) .hubspot-connected-or-salesforce-connected {
    display: none !important;
}

#body:not([hubspot-connected=false][salesforce-connected=false]) .hubspot-not-connected-and-salesforce-not-connected {
    display: none !important;
}

#body:not([hubspot-connected=false]) .hubspot-not-connected {
    display: none !important;
}

#body:not([hubspot-connected=true]) .hubspot-connected {
    display: none !important;
}

#body:not([salesforce-connected=false]) .salesforce-not-connected {
    display: none !important;
}

#body:not([salesforce-connected=true]) .salesforce-connected {
    display: none !important;
}

#body[slack-connected=true] .slack-not-connected {
    display: none !important;
}

#body[slack-connected=false] .slack-connected {
    display: none !important;
}

#body:not([slack-connected=false]):not([slack-connected=true]) .slack-not-connected {
    display: none !important;
}

#body:not([slack-connected=false]):not([slack-connected=true]) .slack-connected {
    display: none !important;
}

#body:not([is-hubspot-admin=false]) .is-not-hubspot-admin {
    display: none !important;
}

#body:not([is-hubspot-admin=true]) .is-hubspot-admin {
    display: none !important;
}

#body:not([is-salesforce-admin=false]) .is-not-salesforce-admin {
    display: none !important;
}

#body:not([is-salesforce-admin=true]) .is-salesforce-admin {
    display: none !important;
}

#body:not([growth-opportunities-visible=true]):not([growth-opportunities-visible=false]) .growth-opportunities-not-visible {
    display: none !important;
}

#body:not([growth-opportunities-visible=true]):not([growth-opportunities-visible=false]) .growth-opportunities-visible {
    display: none !important;
}

#body[growth-opportunities-visible=true] .growth-opportunities-not-visible {
    display: none !important;
}

#body[growth-opportunities-visible=false] .growth-opportunities-visible {
    display: none !important;
}

.save-this-summary-to-hubspot-container.not-checked + .hubspot-contact-info, .save-this-summary-to-salesforce-container.not-checked + .salesforce-contact-info {
    display: none !important;
}

.zoom-onboarding .circle {
    display: flex;
    width: 16px;
    height: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 40px;
    background: var(--gray400);
    color: var(--textPrimary);

    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 9px; /* 90% */
    letter-spacing: 0.4px;
}

.zoom-onboarding .connector {
    height: 2px;
    width: 100px;
    background: var(--gray400);
    position: absolute;
    right: 50%;
    top: 8px;
    z-index: -99;
}

.zoom-onboarding .step.active .connector, .zoom-onboarding .step.active .circle {
  background: var(--brand-primary);
  color: var(--white);
}

.zoom-onboarding .step-body {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.zoom-onboarding .step-body .label {
    margin-top: 8px;
}

.zoom-onboarding .step {
    position: relative;
    width: 100px;
    display: flex;
    justify-content: center;
}

.zoom-onboarding .steps {
    display: flex;
    justify-content: center;
}

.zoom-onboarding .label {
    color: var(--textPrimary);
    text-align: center;


    /* Caption/Bold/10px */
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.4px;
}

.zoom-onboarding .subtitle strong {
    color: #F97238;
}

.connect-your-apps-container .integration-item:hover {
    background: #434343 !important;
}

.recommended-prompts * > .edit-icon, .recommended-prompts * > .delete-icon {
    display: none !important;
}

.recommended-prompts *:hover > * > .edit-icon, .recommended-prompts *:hover > * > .delete-icon {
  display: flex !important;
}

.other-input {
    flex: 1;
    height: 20px;
    padding: 8px 16px;
    background: transparent;
    border-radius: 5px;
    border: 1px #434343 solid;
    color: var(--textPrimary);
}

.other-input:focus-visible {
    outline: none;
    border: 1px #E44867 solid;
}

.language-picker select {
    background-color: #222222;
    color: #c6c6c6;
    outline: #C0C0C0;
    border-radius: 0.4em;
    margin: 0.2em;
}

@keyframes appear-dropdown {
    to {
        opacity: 1;
        display: flex;
    }

    from {
        display: none;
        opacity: 0;
    }
}
@keyframes dissappear-dropdown {
    from {
        opacity: 1;
        display: flex;
    }

    to {
        display: none;
        opacity: 0;
    }
}

.dropdown-wrapper > button.dropdown-button {
    margin-top: 0;
    width: 10rem;
}

.dropdown-wrapper {
    position: relative;
}

.dropdown-options-container:focus-within,
.dropdown-button:focus-within + .dropdown-options-container,
.dropdown-options-container:has(button:active)
{
    animation: appear-dropdown 100ms;
    display: flex;
}

.dropdown-options-container {
    animation: dissappear-dropdown 100ms;
    display: none;
    flex-direction: column;
    position: absolute;
    top: full;
    width: 100%;
    background-color: #202124;
    box-shadow: 0 0 1px 1px #6D6D6D;
    border-radius: 0.4em;
}

.dropdown-options-container > button.option-button {
    padding: 0.5em 1em;
    background: none;
    border: none;
    font-weight: bold;
    color: #FFFFFF;
    text-align: start;
    cursor: pointer;
    padding: 0.5em 1em;
}

.dropdown-options-container > button.option-button:hover {
    background-color: #404040;
}

span.search-word {
    margin-right: 3px;
    display: inline-block;
}